import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Hero from '../components/hero'
import PreviewArticleCategories from '../components/previewArticleCategories'
import RelatedArticles from '../components/relatedArticles'

const CategoriesPage = () => {
    return (
        <>
            <GatsbySeo
                title="Kategorier"
                description="Här hittar du samlingar av artiklar kategoriserade efter olika ämnen."
            />
            <Hero
                heading="Alla kategorier"
                body="Här hittar du samlingar av artiklar kategoriserade efter olika ämnen."
            />
            <PreviewArticleCategories />
            <RelatedArticles headline="Utvalda artiklar" />
        </>
    )
}

export default CategoriesPage
