import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import Cta from './common/cta'
import Card from './card'
import SectionContainer from './layout/sectionContainer'
import * as previewArticleCategories from './previewArticleCategories.module.scss'

const PreviewArticleCategories = ({ showAllArticlesLink }) => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulArticleCategory {
                edges {
                    node {
                        slug
                        id
                        title
                        description {
                            description
                        }
                        image {
                            title
                            gatsbyImageData(
                                width: 848
                                height: 548
                                layout: CONSTRAINED
                                placeholder: BLURRED
                            )
                        }
                        article {
                            id
                        }
                    }
                }
            }
        }
    `)

    const categoriesWithArticles =
        data.allContentfulArticleCategory.edges.filter(
            (articleCategory) => articleCategory.node.article !== null
        )

    return (
        <div>
            <SectionContainer
                title="Kategorier"
                subtitle="Lär dig mer om ett ämne du är intresserad av."
            >
                {categoriesWithArticles.map(
                    ({ node: articleCategory }, index) => (
                        <Card
                            key={articleCategory.id}
                            to={articleCategory.slug}
                            title={articleCategory.title}
                            bodyText={articleCategory.description.description}
                            image={articleCategory.image}
                        />
                    )
                )}
            </SectionContainer>
            {showAllArticlesLink && (
                <div className={previewArticleCategories.showAllLink}>
                    <Cta
                        isLink={true}
                        to="/kategorier"
                        text="Se alla kategorier"
                    />
                </div>
            )}
        </div>
    )
}

PreviewArticleCategories.propTypes = {
    showAllArticlesLink: PropTypes.bool,
}

export default PreviewArticleCategories
